import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Field, Formik } from 'formik';
import styled from '@emotion/styled';
import SymbolSelector from '../PortfolioGroupTargets/TargetBar/SymbolSelector';
import {
  selectAuthorizations,
  selectIsDemo,
  selectMaintenanceBrokerages,
  selectSettings,
} from '../../selectors';
import {
  selectCurrentGroupAccounts,
  selectCurrentGroupId,
  selectCurrentGroupInfo,
} from '../../selectors/groups';
import { StyledSelect } from '../PortfolioGroupSettings/OrderTargetAllocations';
import { Input } from '../../styled/Form';
import { A, H1, H2, H3, P } from '../../styled/GlobalElements';
import { Button } from '../../styled/Button';
import { useState } from 'react';
import { getData, postData } from '../../api';
import { push } from 'connected-react-router';
import Grid from '../../styled/Grid';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCircleNotch,
  faExclamationTriangle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { MarketQuotesType } from '../../types/marketQuotes';
import CurrencyFlag from 'react-currency-flags';
import ReactLoading from 'react-loading';
import ErrorMessage from '../RebalanceWidget/ErrorMessage';
import TradeImpact from './TradeImpact';
import { selectIsPaid } from '../../selectors/subscription';
import { NotificationContainer } from '../NotificationMessage';
import Number from '../Number';
import ExecutedOrderSummary from './ExecutedOrderSummary';
import { toast } from 'react-toastify';
import {
  selectAccounts,
  selectCurrentAccountId,
} from '../../selectors/accounts';
import { reloadEverything } from '../../actions';
import { Brokerage } from '../../types/brokerage';
import { Authorization } from '../../types/authorization';
import { startConnection } from '../../common';
import OverlayLoading from '../OverlayLoading';
import { SnapTradeReact } from 'snaptrade-react';
import { selectIsEmailVerified } from '../../selectors/emailVerified';
import EmailNotVerifiedWarning from '../EmailNotVerifiedWarning';
import { Error } from '../../types/SnapTrade';

type BuySellRadioBtnsProps = {
  selected: string;
};
const BuySellRadioBtns = styled.div<BuySellRadioBtnsProps>`
  margin-bottom: 30px;
  input[type='radio'] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  label {
    width: 50%;
    text-align: center;
    align-items: center;
    cursor: pointer;
    color: var(--brand-green);
    display: inline-block;
    padding: 20px 0px;
    font-family: sans-serif, Arial;
    font-size: 40px;
    font-weight: 200;
    border-radius: 4px;
    border: 2px solid var(--brand-green);
    @media (max-width: 900px) {
      padding: 10px 0px;
      font-size: 16px;
    }
  }

  .buy {
    background: ${(props) =>
      props.selected === 'buy' ? 'var(--brand-green)' : 'var(--white)'};
    color: ${(props) =>
      props.selected === 'buy' ? 'var(--white)' : 'var(--brand-green)'};
    border-right: none;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    @media (max-width: 900px) {
      width: 50%;
    }
  }
  .sell {
    background: ${(props) =>
      props.selected === 'sell' ? 'var(--brand-green)' : 'var(--white)'};
    color: ${(props) =>
      props.selected === 'sell' ? 'var(--white)' : 'var(--brand-green)'};
    border-left: none;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    @media (max-width: 900px) {
      width: 50%;
    }
  }
`;

export const OrderType = styled.div`
  margin-bottom: 30px;
  margin-top: 20px;
  label {
    display: block;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
  }
  select {
    border-radius: 4px;
    width: 450px;
    height: 44px;
    @media (max-width: 900px) {
      width: 250px;
    }
  }
`;

const Quantity = styled.div`
  label {
    display: block;
    font-weight: 600;
    font-size: 22px;
    margin-bottom: 10px;
  }
  input {
    border-radius: 4px;
    max-width: 150px;
    max-height: 44px;
  }
`;
const OrderHeader = styled.div`
  display: block;
  font-weight: 800;
  font-size: 22px;
  margin-bottom: 10px;
`;

const SecuritySelectedPreview = styled.div`
  background-color: var(--brand-light-green);
  padding: 30px;
  margin: 10px 0px 30px;
`;

const PreviewGrid = styled(Grid)`
  margin-top: 50px;
`;

const ClearBtn = styled.button`
  float: right;
  svg {
    font-size: 25px;
  }
`;

const SymbolDiv = styled.div`
  display: flex;
  align-items: baseline;
  h3 {
    font-size: 18px;
    border: 2px solid black;
    padding: 0px 20px;
  }
  p {
    margin-left: 10px;
  }
`;

const MarketPriceDiv = styled.div`
  margin-left: 50px;
  @media (max-width: 900px) {
    margin-left: 0px;
    margin-top: 20px;
  }
`;

const CurrencyDiv = styled.div`
  display: flex;
  align-items: baseline;
  p {
    margin-left: 5px;
  }
`;

const CurrencyCode = styled(P)`
  font-weight: 600;
  margin-left: 5px;
`;

const QuotesError = styled.div`
  color: red;
`;

const PreviewOrderBtn = styled(Button)`
  padding: 20px 110px;
  font-weight: 500;
  font-size: 22px;
  margin-top: 30px;
  @media (max-width: 900px) {
    font-size: 16px;
    padding: 15px 100px;
  }
`;

export const ReactLoadingWithText = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  font-size: inherit;
  div {
    margin-left: 10px;
  }
`;

const PlacedOrder = styled.div`
  text-align: center;
`;

const EliteFeature = styled.div`
  padding: 20px;
  font-weight: 900;
  font-size: 30px;
  color: rgb(4 163 134);
  background: #dbfcf6;
  border-radius: 4px 4px 0 0;
  a {
    font-weight: 900;
    font-size: 18px;
    text-decoration: underline;
    margin-left: 10px;
  }
  p {
    margin-top: 20px;
  }
`;

const NoTrade = styled(NotificationContainer)`
  p,
  a {
    font-size: 18px;
  }
  h2 {
    margin-bottom: 10px;
  }
`;

type selectedSecurityType = {
  symbolId: string;
  accountId: string;
  currencyCode: string;
};

type Props = {
  selectedAccount?: string;
  symbol?: string;
};
const ManualTradeModal = ({ selectedAccount, symbol }: Props) => {
  const dispatch = useDispatch();
  const groupId = useSelector(selectCurrentGroupId);
  const groupAccounts = useSelector(selectCurrentGroupAccounts);
  const allAccounts = useSelector(selectAccounts);
  const connections = useSelector(selectAuthorizations);
  const isPaid = useSelector(selectIsPaid);
  const isDemo = useSelector(selectIsDemo);
  const isEmailVerified = useSelector(selectIsEmailVerified);
  const settings = useSelector(selectSettings);
  const accountId = useSelector(selectCurrentAccountId);
  const maintenanceBrokerages = useSelector(selectMaintenanceBrokerages);
  const currentGroupInfo = useSelector(selectCurrentGroupInfo);

  const [accounts, setAccounts] = useState(allAccounts);
  const [currentAccount, setCurrentAccount] = useState(selectedAccount);
  const [selectedSecurity, setSelectedSecurity] = useState<
    selectedSecurityType
  >();
  const [clearInputSelector, setClearInputSelector] = useState(0);
  const [quotes, setQuotes] = useState<MarketQuotesType>();
  const [loadingQuotes, setLoadingQuotes] = useState(false);
  const [haveOrderImpact, setHaveOrderImpact] = useState(false);
  const [orderImpact, setOrderImpact] = useState<any>();
  const [secondOrderImpact, setSecondOrderImpact] = useState<any>();
  const [fetchingOrderImpact, setFetchingOrderImpact] = useState(false);
  const [orderImpactError, setOrderImpactError] = useState<any>();
  const [placedOrder, setPlacedOrder] = useState(false);
  const [orderError, setOrderError] = useState<any>();
  const [executedOrderSummary, setExecutedOrderSummary] = useState<any>();
  const [initialSecurity, setInitialSecurity] = useState({
    id: '',
    symbol: '',
    description: '',
    currency: {
      id: '',
      code: '',
      name: '',
    },
    type: {
      id: '',
      code: '',
      is_supported: false,
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isSnapTradeModalOpen, setIsSnapTradeModalOpen] = useState(false);
  const [snapTradeLoginLink, setSnapTradeLoginLink] = useState('');
  const [brokerageName, setBrokerageName] = useState<string | undefined>();

  useEffect(() => {
    setAccounts(groupAccounts);
    if (symbol) {
      postData('/api/v1/symbols', {
        substring: symbol,
      }).then((res) => {
        setInitialSecurity(res.data[0]);
        if (selectedAccount) {
          setSelectedSecurity({
            symbolId: res.data[0].id,
            accountId: selectedAccount,
            currencyCode: res.data[0].currency.code,
          });
        }
      });
    }
    if (accountId) {
      setCurrentAccount(accountId);
    }
    // eslint-disable-next-line
  }, []);

  const orderTypes = [
    'Market',
    'Limit',
    // We disabled OCO orders for now
    //'One Cancels Other'
  ];

  useEffect(() => {
    if (
      selectedSecurity &&
      selectedSecurity.symbolId &&
      selectedSecurity.accountId
    ) {
      setLoadingQuotes(true);
      getData(
        `api/v1/accounts/${selectedSecurity?.accountId}/quotes?symbols=${selectedSecurity?.symbolId}`,
      )
        .then((res) => {
          setQuotes(res.data[0]);
          setLoadingQuotes(false);
        })
        .catch(() => {
          setLoadingQuotes(false);
        });
    }
  }, [selectedSecurity]);

  const isTradeEnabled = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId);

    const auth = connections?.find(
      (auth) => auth.id === account?.brokerage_authorization,
    );
    return auth?.type === 'trade';
  };

  const isTradeSupported = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId);

    const auth = connections?.find(
      (auth) => auth.id === account?.brokerage_authorization,
    );
    return auth?.brokerage.allows_trading;
  };

  const upgradeBrokerageAuthorizationTradePermissionsByAccount = async (
    accountId: string,
  ) => {
    const account = accounts.find((account) => account.id === accountId);
    const brokerageAuthorization = connections?.find(
      (auth) => auth.id === account?.brokerage_authorization,
    );

    setBrokerageName(brokerageAuthorization?.brokerage.name);

    try {
      const loginLink = await startConnection({
        updateBrokerageAuthId: brokerageAuthorization?.id,
        connectionType: 'trade',
      });
      setSnapTradeLoginLink(loginLink);
      setIsSnapTradeModalOpen(true);
      setIsLoading(false);
    } catch (error) {}
  };

  const isCrypto = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId);
    return account?.meta.type === 'Crypto';
  };

  const isManualOrderEnabled = (accountId: string) => {
    const account = accounts.find((account) => account.id === accountId);
    let canTrade = true;
    if (account?.meta.institution_name === 'Interactive Brokers') {
      canTrade = false;
    }
    return canTrade;
  };

  const executeTrade = (tradeId: string) => {
    postData(`api/v1/trade/${tradeId}`, {})
      .then((res) => {
        setPlacedOrder(true);
        setExecutedOrderSummary(res.data);
        dispatch(reloadEverything());
      })
      .catch((err) => {
        setOrderError(true);
      });
  };

  const executeOcoTrade = (trade1Id: string, trade2Id: string) => {
    postData(`api/v1/trade/oco`, {
      first_trade_id: trade1Id,
      second_trade_id: trade2Id,
    })
      .then((res) => {
        setPlacedOrder(true);
        setExecutedOrderSummary(res.data);
      })
      .catch((err) => {
        setOrderError(true);
      });
  };

  const authSuccess = (authId: string) => {
    dispatch(push(`/connection-complete/${brokerageName}?authId=${authId}`));
  };

  const authError = (data: Error) => {
    const { errorCode } = data;
    if (errorCode == null || !(['1066', '1067'].includes(errorCode))) {
      dispatch(
        push(
          `/connection-complete/${brokerageName}?errorCode=${data.errorCode}`,
        ),
      );
    }
  };

  const resetEverything = () => {
    setHaveOrderImpact(false);
    setOrderImpactError(null);
    setPlacedOrder(false);
    setOrderError(false);
    setOrderImpact(null);
    setSecondOrderImpact(null);
  };

  return (
    <div>
      {isLoading ? (
        <OverlayLoading />
      ) : placedOrder ? (
        <ExecutedOrderSummary
          data={executedOrderSummary}
          reset={resetEverything}
        />
      ) : orderError ? (
        <PlacedOrder>
          <FontAwesomeIcon
            icon={faExclamationTriangle}
            color="var(--brand-orange)"
            size="3x"
          />
          <H1>Failed to execute the order. Please try again! </H1>
        </PlacedOrder>
      ) : haveOrderImpact && orderImpact ? (
        <TradeImpact
          orderImpact={orderImpact}
          secondOrderImpact={secondOrderImpact}
          executeTrade={executeTrade}
          executeOcoTrade={executeOcoTrade}
          currencyCode={selectedSecurity?.currencyCode}
          cancelTrade={resetEverything}
        />
      ) : orderImpactError ? (
        <ErrorMessage
          groupId={groupId ? groupId : ''}
          error={orderImpactError}
          closeWidget={() => setOrderImpactError(null)}
          backgroundColor="var(--brand-light-orange)"
        />
      ) : (
        <Formik
          initialValues={{
            type: 'buy',
            account: currentAccount ? currentAccount : accounts[0]?.id,
            security: initialSecurity,
            orderType: 'Market',
            quantity: 1,
            price: 0,
            duration: 'Day',
            stopPrice: 0,
            stopLimit: 0,
          }}
          enableReinitialize
          validate={(values) => {
            const errors: any = {};
            if (values.quantity === 0) {
              errors.quantity = 'Quantity cannot be 0.';
            } else if (values.quantity < 0) {
              errors.quantity = 'Quantity cannot be negative.';
            }
            return errors;
          }}
          onSubmit={(values, { setSubmitting, resetForm }) => {
            setFetchingOrderImpact(true);
            if (
              maintenanceBrokerages.some((brokerage: Brokerage) =>
                currentGroupInfo?.brokerage_authorizations.some(
                  (authorization: Authorization) => {
                    return brokerage.id === authorization.brokerage.id;
                  },
                ),
              )
            ) {
              toast.error(
                'Sorry, you cannot place a trade with this brokerage at the moment because it is undergoing maintenance. Please try again later.',
              );
              setFetchingOrderImpact(false);
            } else {
              if (values.orderType !== 'One Cancels Other') {
                const toSubmit = {
                  account_id: selectedSecurity?.accountId,
                  universal_symbol_id: selectedSecurity?.symbolId,
                  order_type: values.orderType,
                  time_in_force: 'Day',
                  action: values.type.toUpperCase(),
                  units: values.quantity + '',
                  price: values.price + '',
                };
                postData(`api/v1/trade/impact`, toSubmit)
                  .then((res) => {
                    setFetchingOrderImpact(false);
                    setHaveOrderImpact(true);
                    setOrderImpact(res.data);
                    setSubmitting(false);
                    resetForm();
                  })
                  .catch((err) => {
                    setFetchingOrderImpact(false);
                    setOrderImpactError(err.response.data);
                  });
              } else {
                const limitToSubmit = {
                  account_id: selectedSecurity?.accountId,
                  universal_symbol_id: selectedSecurity?.symbolId,
                  order_type: 'Limit',
                  time_in_force: 'Day',
                  action: values.type.toUpperCase(),
                  units: values.quantity + '',
                  price: values.price + '',
                };
                const stopToSubmit = {
                  account_id: selectedSecurity?.accountId,
                  universal_symbol_id: selectedSecurity?.symbolId,
                  order_type: 'StopLimit',
                  time_in_force: 'Day',
                  action: values.type.toUpperCase(),
                  units: values.quantity + '',
                  stop: values.stopPrice + '',
                  price: values.stopLimit + '',
                };
                postData(`api/v1/trade/impact`, limitToSubmit)
                  .then((res) => {
                    setFetchingOrderImpact(false);
                    setHaveOrderImpact(true);
                    setOrderImpact(res.data);
                    setSubmitting(false);
                    resetForm();
                  })
                  .catch((err) => {
                    setFetchingOrderImpact(false);
                    setOrderImpactError(err.response.data);
                  });
                postData(`api/v1/trade/impact`, stopToSubmit)
                  .then((res) => {
                    setFetchingOrderImpact(false);
                    setHaveOrderImpact(true);
                    setSecondOrderImpact(res.data);
                    setSubmitting(false);
                    resetForm();
                  })
                  .catch((err) => {
                    setFetchingOrderImpact(false);
                    setOrderImpactError(err.response.data);
                  });
              }
            }
          }}
        >
          {({
            values,
            errors,
            handleChange,
            setFieldValue,
            handleSubmit,
            isValid,
            isSubmitting,
          }) => (
            <form>
              <BuySellRadioBtns
                role="group"
                aria-labelledby="radio-group"
                selected={values.type}
              >
                <label className="buy" htmlFor="buy">
                  <Field type="radio" name="type" id="buy" value="buy" />
                  BUY
                </label>
                <label className="sell" htmlFor="sell">
                  <Field type="radio" name="type" id="sell" value="sell" />
                  SELL
                </label>
              </BuySellRadioBtns>

              <OrderType>
                <label htmlFor="account">Account </label>
                <StyledSelect
                  as="select"
                  name="account"
                  id="account"
                  required
                  onChange={(e: any) => {
                    handleChange(e);
                    if (selectedSecurity) {
                      setSelectedSecurity({
                        symbolId: selectedSecurity.symbolId,
                        accountId: e.target.value,
                        currencyCode: selectedSecurity.currencyCode,
                      });
                    }
                  }}
                >
                  {accounts?.map((account) => (
                    <option value={account.id} key={account.id}>
                      {account.name}
                    </option>
                  ))}
                </StyledSelect>
              </OrderType>

              {!isEmailVerified && !settings?.pre_email_validation_user ? (
                <EmailNotVerifiedWarning view="Trade" />
              ) : !isPaid && !isDemo ? (
                <EliteFeature>
                  Elite Feature
                  <A onClick={() => dispatch(push('/questrade-offer'))}>
                    Upgrade Now!
                  </A>
                  {settings?.dark_mode_enabled ? (
                    <P color="black">
                      Upgrade to get access to all basic features plus the
                      option to place orders through Passiv.
                    </P>
                  ) : (
                    <P>
                      Upgrade to get access to all basic features plus the
                      option to place orders through Passiv.
                    </P>
                  )}
                </EliteFeature>
              ) : !isTradeSupported(values.account) ? (
                <NoTrade
                  error={true}
                  darkModeEnabled={settings?.dark_mode_enabled}
                >
                  <>
                    <H2 darkModeEnabled={settings?.dark_mode_enabled}>
                      Brokerage does not support trading
                    </H2>
                    <P darkModeEnabled={settings?.dark_mode_enabled}>
                      Unfortunately, currently we are not supporting Trading for
                      this broker. <br />
                      Please check back later.
                    </P>
                  </>
                </NoTrade>
              ) : !isTradeEnabled(values.account) ? (
                <NoTrade
                  error={true}
                  darkModeEnabled={settings?.dark_mode_enabled}
                >
                  <>
                    <H2 darkModeEnabled={settings?.dark_mode_enabled}>
                      Passiv needs trading permissions to place orders
                    </H2>
                    <P darkModeEnabled={settings?.dark_mode_enabled}>
                      This portfolio group does not have trade permissions and
                      therefore can't be used to place orders.
                    </P>
                    <P darkModeEnabled={settings?.dark_mode_enabled}>
                      Reconnect with full trade permissions to place orders with
                      Passiv:
                    </P>
                    <Button
                      onClick={() =>
                        upgradeBrokerageAuthorizationTradePermissionsByAccount(
                          values.account,
                        )
                      }
                      type="button"
                    >
                      Upgrade Permissions
                    </Button>
                  </>
                </NoTrade>
              ) : !isManualOrderEnabled(values.account) ? (
                <NoTrade error={true}>
                  <H2 darkModeEnabled={settings?.dark_mode_enabled}>
                    Brokerage is not supported yet
                  </H2>
                  <P darkModeEnabled={settings?.dark_mode_enabled}>
                    Unfortunately, currently we are not supporting Manual Trade
                    for this broker. <br />
                    Please check back later.
                  </P>
                </NoTrade>
              ) : (
                isCrypto(values.account) && (
                  <NoTrade error={true}>
                    <H2>Crypto accounts are not supported yet</H2>
                    <P>
                      Unfortunately, currently we are not supporting Manual
                      Trade for Crypto accounts. <br />
                      Please check back later.
                    </P>
                  </NoTrade>
                )
              )}

              {isTradeEnabled(values.account) &&
                isTradeEnabled(values.account) &&
                (isPaid || isDemo) &&
                !isCrypto(values.account) &&
                isManualOrderEnabled(values.account) && (
                  <>
                    <SymbolSelector
                      value={undefined}
                      accountId={values.account}
                      clearInput={clearInputSelector}
                      onSelect={(cb) => {
                        setFieldValue('security', cb);
                        setSelectedSecurity({
                          symbolId: cb.id,
                          accountId: values.account,
                          currencyCode: cb.currency.code,
                        });
                        setClearInputSelector(clearInputSelector + 1);
                      }}
                    />

                    {values.security && values.security.id !== '' && (
                      <SecuritySelectedPreview>
                        <ClearBtn
                          onClick={() =>
                            setFieldValue('security', initialSecurity)
                          }
                        >
                          <FontAwesomeIcon icon={faTimes} />
                        </ClearBtn>
                        <PreviewGrid columns="2fr 1fr">
                          <SymbolDiv>
                            <H3 darkModeEnabled={settings?.dark_mode_enabled}>
                              {values.security.symbol}
                            </H3>
                            <P darkModeEnabled={settings?.dark_mode_enabled}>
                              {values.security.description}
                            </P>
                          </SymbolDiv>
                          <MarketPriceDiv>
                            <H3 darkModeEnabled={settings?.dark_mode_enabled}>
                              Market Price:
                            </H3>
                            {loadingQuotes ? (
                              <FontAwesomeIcon icon={faCircleNotch} spin />
                            ) : quotes ? (
                              <CurrencyDiv>
                                <span>
                                  <CurrencyFlag
                                    currency={values.security.currency.code}
                                    size="md"
                                  />
                                </span>
                                <CurrencyCode>
                                  {values.security.currency.code}
                                </CurrencyCode>
                                <P>
                                  <Number
                                    value={quotes?.ask_price}
                                    currency={values.security.currency.code}
                                  />
                                </P>
                              </CurrencyDiv>
                            ) : (
                              <QuotesError>
                                Not tradeable on this brokerage account
                              </QuotesError>
                            )}
                          </MarketPriceDiv>
                        </PreviewGrid>
                      </SecuritySelectedPreview>
                    )}
                    <OrderType>
                      <label htmlFor="orderType">Order Type </label>
                      <StyledSelect
                        as="select"
                        name="orderType"
                        id="orderType"
                        required
                      >
                        {orderTypes.map((order, index) => {
                          return (
                            <option value={order} key={order}>
                              {order}
                            </option>
                          );
                        })}
                      </StyledSelect>
                    </OrderType>
                    {values.orderType === 'Limit' && (
                      <div>
                        <OrderType>
                          <label htmlFor="duration">Duration</label>

                          <StyledSelect
                            as="select"
                            name="duration"
                            id="duration"
                            required
                          >
                            <option value={'Day'} key={'Day'}>
                              Day
                            </option>
                          </StyledSelect>
                        </OrderType>
                        <Quantity>
                          <label htmlFor="price">Limit Price</label>
                          <Input
                            type="number"
                            id="price"
                            value={values.price}
                            name="price"
                            onChange={handleChange}
                            required
                          />
                        </Quantity>
                      </div>
                    )}
                    {values.orderType === 'One Cancels Other' && (
                      <div>
                        <OrderType>
                          <label htmlFor="duration">Duration</label>

                          <StyledSelect
                            as="select"
                            name="duration"
                            id="duration"
                            required
                          >
                            <option value={'Day'} key={'Day'}>
                              Day
                            </option>
                          </StyledSelect>
                        </OrderType>
                        <OrderHeader>Limit Order:</OrderHeader>
                        <Quantity>
                          <label htmlFor="price">Price</label>
                          <Input
                            type="number"
                            id="price"
                            value={values.price}
                            name="price"
                            onChange={handleChange}
                            required
                          />
                        </Quantity>
                        <OrderHeader>Stop Limit Order:</OrderHeader>
                        <Quantity>
                          <label htmlFor="price">Stop</label>
                          <Input
                            type="number"
                            id="stopPrice"
                            value={values.stopPrice}
                            name="stopPrice"
                            onChange={handleChange}
                            required
                          />
                        </Quantity>
                        <Quantity>
                          <label htmlFor="price">Limit</label>
                          <Input
                            type="number"
                            id="stopLimit"
                            value={values.stopLimit}
                            name="stopLimit"
                            onChange={handleChange}
                            required
                          />
                        </Quantity>
                      </div>
                    )}
                    <Quantity>
                      <label htmlFor="quantity">Quantity</label>
                      <Input
                        type="number"
                        id="quantity"
                        value={values.quantity}
                        name="quantity"
                        onChange={handleChange}
                        required
                      />
                      {errors.quantity && (
                        <QuotesError>{errors.quantity}</QuotesError>
                      )}
                    </Quantity>

                    <PreviewOrderBtn
                      type="button"
                      onClick={() => handleSubmit()}
                      disabled={
                        !isValid ||
                        values.security.id === '' ||
                        isSubmitting ||
                        !quotes
                      }
                      darkModeEnabled={settings?.dark_mode_enabled}
                    >
                      {fetchingOrderImpact ? (
                        <ReactLoadingWithText>
                          Validating Order
                          <ReactLoading
                            type="bubbles"
                            color="var(--white)"
                            width={30}
                            height={30}
                          />
                        </ReactLoadingWithText>
                      ) : (
                        'Preview Order'
                      )}
                    </PreviewOrderBtn>
                  </>
                )}
            </form>
          )}
        </Formik>
      )}
      <SnapTradeReact
        key="manual-trade"
        loginLink={snapTradeLoginLink}
        isOpen={isSnapTradeModalOpen}
        closeOnOverlayClick={false}
        close={() => {
          setIsSnapTradeModalOpen(false);
        }}
        onSuccess={authSuccess}
        onError={authError}
      />
    </div>
  );
};

export default ManualTradeModal;
